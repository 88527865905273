import { slave as Gateway } from "@nsftx/seven-gravity-gateway";
import eventBus from "@/utility/eventBus";
import type { Token } from "@/types";

let instance: typeof Gateway;
let existingAuthToken: Token;

export default {
  init() {
    return new Promise((resolve) => {
      instance = Gateway({
        allowedOrigins: import.meta.env.VITE_APP_ALLOWED_ORIGINS,
        slaveId: import.meta.env.VITE_APP_SLAVE_ID,
        load: (message: { data: unknown }) => {
          resolve(message.data);
        },
      });

      this.setListeners();
    });
  },

  setListeners() {
    instance?.subscribe(
      "User.AuthorizationChanged",
      (message: {
        data: {
          auth: { token: Token; tpToken: Token };
        };
      }) => {
        if (existingAuthToken !== message.data.auth.token) {
          existingAuthToken = message.data.auth.token;
          eventBus.publish("User.AuthorizationChanged", message.data);
        }
      }
    );
  },

  requireUserLogin() {
    instance?.sendMessage({
      action: "User.LoginRequired",
    });
  },

  notifyAppLoaded() {
    instance?.sendMessage({
      action: "Slave.Loaded",
    });
  },
};
